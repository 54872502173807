import { I18nLanguage } from '../models/i18n.models';

export const AVALIABLE_LANGUAGES: I18nLanguage[] = [
  {
    short: 'en',
    text: 'EN',
  },
  {
    short: 'th',
    text: 'TH',
  },
];
